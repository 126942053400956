import Dropdown from 'components/dropdown'
import DatePicker from 'components/datePicker'
import TextInput from 'components/textInput'
import { addMonths, format, subYears, addDays } from 'date-fns'
import { Modal } from '@HometreeEngineering/component-library'
import Checkbox from 'components/checkbox'
import { updateMarketingPreferences } from 'api/marketingPreferences'
import { fetchLatestCheckoutState, useCheckoutContext } from 'contexts/checkout'
import { useEffect, useState } from 'react'
import { packageContainsLGSR } from 'utils/packageContainsLGSR'
import { TEXT_REGEX, PROPERTIES_ENDPOINT } from 'config/constants'
import { UpdatePropertyDetailsRequestPayload } from 'types/updatePropertyDetails'
import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import BubbleHeading from 'components/bubbleHeading'
import { sendPageLandAnalytics } from 'features/analytics'
import { isCookieExpired } from './utils/isCookieExpired'
import styles from './index.module.scss'
import { useOmniconvertGoal } from './useOmniconvertGoal'

const API_BASE_URL = process.env.REACT_APP_CHECKOUT_API_BASE_URL

export const generateBoilerMakeOptions = () => [
    {
        value: '',
        displayValue: 'Please select...',
        disabled: true,
    },
    { value: 'Worcester Bosch', displayValue: 'Worcester Bosch' },
    { value: 'Vaillant', displayValue: 'Vaillant' },
    { value: 'Ideal', displayValue: 'Ideal' },
    { value: 'Baxi', displayValue: 'Baxi' },
    { value: 'Potterton', displayValue: 'Potterton' },
    { value: 'Alpha', displayValue: 'Alpha' },
    { value: 'Vokera', displayValue: 'Vokera' },
    { value: 'Glow-worm', displayValue: 'Glow-worm' },
    { value: 'Ferroli', displayValue: 'Ferroli' },
    { value: 'Viessmann', displayValue: 'Viessmann' },
    { value: 'Intergas', displayValue: 'Intergas' },
    { value: 'Ravenheat', displayValue: 'Ravenheat' },
    { value: 'Biasi', displayValue: 'Biasi' },
    { value: 'Atag', displayValue: 'Atag' },
    { value: 'Keston', displayValue: 'Keston' },
    { value: 'Main', displayValue: 'Main' },
    { value: 'Ariston', displayValue: 'Ariston' },
    { value: 'Remeha', displayValue: 'Remeha' },
    {
        value: 'other',
        displayValue: 'Other',
    },
]

export const generateBoilerAgeOptions = () => [
    {
        value: '',
        displayValue: 'Optional',
        disabled: true,
    },
    { value: '1 - 3', displayValue: '1 - 3 years old' },
    { value: '3 - 5', displayValue: '3 - 5 years old' },
    { value: '5 - 10', displayValue: '5 - 10 years old' },
    { value: '10+', displayValue: '10+ years old' },
]

export const generateNextServiceDateOptions = (startDate: Date) => {
    const options = [
        {
            value: '',
            displayValue: 'Please select...',
            disabled: true,
        },
    ]

    for (let i = 0; i < 12; i += 1) {
        const date = addMonths(startDate, i + 1)

        const monthIndex = date.getMonth()

        options.push({
            value: format(date, 'yyyy-MM'),
            displayValue: format(date, 'MMMM yyyy'),
            disabled: !!(monthIndex >= 9 || monthIndex <= 1),
        })
    }

    return options
}

function Confirmation(): JSX.Element {
    const { dispatch, state } = useCheckoutContext()
    const navigate = useNavigate()

    const [boilerMake, setBoilerMake] = useState<string | undefined>('')
    const [boilerMakeError, setBoilerMakeError] = useState<string | undefined>('')
    const [boilerModel, setBoilerModel] = useState<string>('')
    const [boilerAge, setBoilerAge] = useState<string>('')
    const [specificBoilerMake, setSpecificBoilerMake] = useState<string>('')
    const [specificBoilerMakeError, setSpecificBoilerMakeError] = useState<string | undefined>('')
    const [nextServiceDate, setNextServiceDate] = useState<string | undefined>(
        state.nextServiceDate
    )
    const [nextServiceError] = useState<string | undefined>('')
    const [lastServiceDate, setLastServiceDate] = useState<Date>()
    const [lastServiceError, setLastServiceError] = useState<string | undefined>('')
    const [showModal, setShowModal] = useState<boolean | undefined>(!state.marketingOptIn)

    useOmniconvertGoal(state.contractId, state.monthlyTotalPrice)

    useEffect(() => {
        sendPageLandAnalytics('Confirmation', 'PaymentPage')
        if (isCookieExpired()) setShowModal(false)
    }, [])

    const sendBoilerInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const target = e.target as HTMLButtonElement

        setBoilerMakeError('')
        setSpecificBoilerMakeError('')
        setBoilerAge('')
        setLastServiceError('')

        if (!boilerMake || !new RegExp(TEXT_REGEX).test(boilerMake)) {
            setBoilerMakeError('Please select a boiler make')
            return
        }

        if (
            boilerMake === 'other' &&
            (!specificBoilerMake || !new RegExp(TEXT_REGEX).test(specificBoilerMake))
        ) {
            setSpecificBoilerMakeError('Please specify a boiler make')
            return
        }

        if (packageContainsLGSR(state.packageDetails) && !lastServiceDate) {
            setLastServiceError('Please select a valid date')
            return
        }

        target.disabled = true
        target.innerText = 'Sending...'

        if (!state.contractId) {
            throw new Error('Contract ID not found in register customer response')
            return
        }

        const uploadBoilerInfoPayload: UpdatePropertyDetailsRequestPayload = {
            contract_id: state.contractId,
            boiler_make: specificBoilerMake || boilerMake,
            boiler_model: boilerModel,
            boiler_age: boilerAge,
        }

        if (packageContainsLGSR(state.packageDetails)) {
            uploadBoilerInfoPayload.last_service = lastServiceDate?.toISOString() || undefined
        } else {
            uploadBoilerInfoPayload.next_service = nextServiceDate
        }

        await fetch(
            `${API_BASE_URL}/${PROPERTIES_ENDPOINT}/pub/v1/properties/${state.propertyId}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify(uploadBoilerInfoPayload),
            }
        )

        navigate('/feedback')
    }

    const isConfirmButtonDisabled =
        state.marketingEmail || state.marketingPhone || state.marketingSms

    const handleMarketingPreferences = async () => {
        setShowModal(false)

        if (isCookieExpired()) return

        const latestestState = await fetchLatestCheckoutState()

        await updateMarketingPreferences(state, latestestState)
    }

    const isButtonDisabled =
        !boilerMake ||
        !new RegExp(TEXT_REGEX).test(boilerMake) ||
        (boilerMake === 'other' &&
            (!specificBoilerMake || !new RegExp(TEXT_REGEX).test(specificBoilerMake)))

    return (
        <>
            <script>;</script>
            <BubbleHeading>Welcome to Hometree!</BubbleHeading>

            <form className={styles.form}>
                <h2>What&apos;s next</h2>
                <p>
                    Check your inbox for your welcome email, which includes your policy details.
                    (Don’t forget to check your spam folder, in case it’s landed there!)
                </p>
                <h2>Tell us about your boiler</h2>
                <p>
                    Please share what you know about your boiler - it&apos;s ok if you don&apos;t
                    know everything right now. This information helps us prepare for your future
                    claims, and does not alter the price of the cover plan.
                </p>
                <Dropdown
                    header="Boiler make"
                    testId="boilerMakeDropdown"
                    selected={boilerMake}
                    options={generateBoilerMakeOptions()}
                    onChange={(value) => {
                        setBoilerMake(value)
                        setSpecificBoilerMake('')
                    }}
                    error={boilerMakeError}
                />
                {showModal && (
                    <Modal
                        isOpen={showModal}
                        setIsOpen={() => {
                            setShowModal(false)
                        }}
                    >
                        <>
                            <h2 className={styles.confirmation_pg_modal_title}>Keeping in touch</h2>
                            <p className={styles.confirmation_pg_modal_text}>
                                Sign up to hear about the latest offers and products from Hometree
                                Group. Let us know how you would like us to keep in touch below. You
                                can update your preferences at any time.
                            </p>
                            <section className={styles.confirmation_checkboxes}>
                                <Checkbox
                                    id="confirmation_pg_Marketing_Email"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'setCheckout',
                                            data: {
                                                marketingEmail: e.target.checked,
                                                referralProgramme: e.target.checked,
                                            },
                                        })
                                    }}
                                >
                                    Email
                                </Checkbox>
                                <Checkbox
                                    id="confirmation_pg_Marketing_ChangePhone"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'setCheckout',
                                            data: {
                                                marketingPhone: e.target.checked,
                                                referralProgramme: e.target.checked,
                                            },
                                        })
                                    }}
                                >
                                    Phone
                                </Checkbox>
                                <Checkbox
                                    id="confirmation_pg_Marketing_ChangeSms"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'setCheckout',
                                            data: {
                                                marketingSms: e.target.checked,
                                                referralProgramme: e.target.checked,
                                            },
                                        })
                                    }}
                                >
                                    Text
                                </Checkbox>
                            </section>
                            <Button
                                disabled={!isConfirmButtonDisabled}
                                onClick={() => handleMarketingPreferences()}
                                variant="purple"
                                className={styles.modal_confirmation}
                            >
                                Confirm
                            </Button>
                        </>
                    </Modal>
                )}
                {boilerMake === 'other' && (
                    <TextInput
                        header="Specify boiler make"
                        id="specificBoilerMake"
                        testId="specificBoilerMake"
                        placeholder="Specify your boiler make"
                        value={specificBoilerMake}
                        onChange={setSpecificBoilerMake}
                        error={specificBoilerMakeError}
                    />
                )}
                <TextInput
                    header="Boiler model"
                    id="boilerModelInput"
                    testId="boilerModelInput"
                    placeholder="Optional"
                    value={boilerModel}
                    onChange={setBoilerModel}
                />
                <Dropdown
                    header="Boiler age"
                    testId="boilerAgeDropdown"
                    selected={boilerAge}
                    options={generateBoilerAgeOptions()}
                    onChange={(value) => setBoilerAge(value)}
                />
                {packageContainsLGSR(state.packageDetails) ? (
                    <div>
                        <DatePicker
                            id="lastServiceDatePicker"
                            dataTestId="lastServiceDatePicker"
                            header="When does your Landlord Gas Safety Record expire?"
                            value={lastServiceDate}
                            onChange={setLastServiceDate}
                            error={lastServiceError}
                            minDate={subYears(new Date(), 3)}
                            maxDate={addDays(new Date(), 366)}
                        />
                    </div>
                ) : (
                    <Dropdown
                        header="Your boiler service will be scheduled for"
                        testId="nextServiceDropdown"
                        selected={nextServiceDate}
                        options={generateNextServiceDateOptions(state.date || new Date())}
                        onChange={setNextServiceDate}
                        error={nextServiceError}
                    />
                )}
                <p>
                    Your plan includes a boiler service, we carry them out between March and
                    September and close to the anniversary of your cover plan start date. This helps
                    us maximise our emergency response time during the busier winter months.
                </p>

                <Button disabled={isButtonDisabled} onClick={(e) => sendBoilerInfo(e)}>
                    Submit
                </Button>
            </form>
        </>
    )
}

export default Confirmation
