import { useState, useEffect } from 'react'
import { PromoPhase } from 'features/promoMode/promo-phase.class'
import styles from './index.module.scss'
import { calculateTimeLeft } from '../utils'

type Props = {
    phase: PromoPhase
}

function Countdown({ phase }: Props): JSX.Element {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(phase.period.end))
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(phase.period.end))
        }, 1000)
        return () => clearTimeout(timer)
    })

    return (
        <>
            <div className={`${styles.mobile} ${styles.countdownRow}`} data-testid="promo-banner">
                <span className={styles.offerEndsText}>
                    {phase.banner.countdownTextMobile ?? 'Offer Ends'}
                </span>
                <span className={`${styles.countdown} ${styles.countdownNear}`}>
                    <span className={styles.unit}>
                        <span className={styles.value}>{timeLeft.days}</span>
                        <span className={styles.text}>Days</span>
                    </span>
                    <span className={styles.unit}>
                        <span className={styles.value}>{timeLeft.hours}</span>
                        <span className={styles.text}>Hours</span>
                    </span>
                    <span className={styles.unit}>
                        <span className={styles.value}>{timeLeft.minutes}</span>
                        <span className={styles.text}>Mins</span>
                    </span>
                    <span className={styles.unit}>
                        <span className={styles.value}>{timeLeft.seconds}</span>
                        <span className={styles.text}>Secs</span>
                    </span>
                </span>
            </div>

            <div className={`${styles.mobile} ${styles.content}`}>
                <h3>{phase.banner.primaryTextMobile || phase.banner.primaryText}</h3>
                <span className={styles.tc}>{phase.banner.secondaryText}</span>
            </div>
            <div className={`${styles.desktop} ${styles.promoBanner}`} data-testid="promo-banner">
                <div className={styles.content}>
                    <h3>{phase.banner.primaryText}</h3>
                    <span className={styles.tc}>{phase.banner.secondaryText}</span>
                    <span className={styles.countdown}>
                        <span className={styles.unit}>
                            <span className={styles.value}>{timeLeft.days}</span>
                            <span className={styles.text}>Days</span>
                        </span>
                        <span className={styles.unit}>
                            <span className={styles.value}>{timeLeft.hours}</span>
                            <span className={styles.text}>Hours</span>
                        </span>
                        <span className={styles.unit}>
                            <span className={styles.value}>{timeLeft.minutes}</span>
                            <span className={styles.text}>Mins</span>
                        </span>
                        <span className={styles.unit}>
                            <span className={styles.value}>{timeLeft.seconds}</span>
                            <span className={styles.text}>Secs</span>
                        </span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default Countdown
