import { Routes, Route } from 'react-router-dom'
import Landing from 'features/ctm/landing'
import HomeownerProducts from 'features/ctm/homeowner'
import LandlordProducts from 'features/ctm/landlord'
import Feedback from 'routes/feedback'
import Checkout from './checkout'
import Confirmation from './confirmation'
import RegisterCustomer from './register'
import Success from './success'

function CTMRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/products/compare-the-market" Component={HomeownerProducts} />
            <Route path="/products/compare-the-market-landlord" Component={LandlordProducts} />
            <Route path="/register-customer" Component={RegisterCustomer} />
            <Route path="/confirmation" Component={Confirmation} />
            <Route path="/feedback" Component={Feedback} />
            <Route path="/success" Component={Success} />
            <Route path="/checkout" Component={Checkout} />
            <Route path="/" Component={Landing} />
        </Routes>
    )
}

export default CTMRoutes
