import { Promo } from '../promo.class'

export const ctmPromo = new Promo()
ctmPromo.addPhase({
    period: {
        start: new Date(2024, 7, 15, 9, 0, 0),
        end: new Date(2024, 8, 30, 23, 59, 59),
    },
    countdown: true,
    pricebooks: {
        promoBook: 56,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: 'AUTUMN SALE - up to 17% off selected plans',
        primaryTextMobile: <>17% Off Select Plans Until 30th Sep &apos;24</>,
        secondaryText: (
            <>
                For new customers only; see{' '}
                <a
                    href="https://www.hometree.co.uk/wp-content/uploads/2024/08/Hometree_ComparetheMarket-Autumn-exclusive-offer-terms-and-conditions.docx-1.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    T&Cs
                </a>{' '}
                for details
            </>
        ),
    },
    ctm: {
        exclusive: true,
        stickers: {
            enabled: true,
            text: null,
            yourBoiler: null,
            yourHeating: (
                <>
                    5%
                    <br />
                    off
                </>
            ),
            yourHome: (
                <>
                    17%
                    <br />
                    off
                </>
            ),
        },
        landing: {
            header: <></>,
            subheader: <></>,
        },
        plans: {
            header: 'Up to 17% Off Select Plans Until 30th September',
            headerMobile: '17% Off Select Plans Until 30th Sep 2024',
            subheader: (
                <>
                    Offer ends at midnight 30th September - for new customers only; see{' '}
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2024/08/Hometree_ComparetheMarket-Autumn-exclusive-offer-terms-and-conditions.docx-1.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    for details
                </>
            ),
            strikethrough: true,
        },
    },
    checkout: {
        strikethrough: true,
    },
})
