import { CheckoutState } from 'contexts/checkout/type'

export const isCTMHost = () => {
    if (
        // Added to /etc/hosts for local development
        window.location.hostname === 'ctm.hometree.local' ||
        window.location.hostname === 'hometree.comparethemarket.com' ||
        window.location.hostname === 'ctm.stage.hometree.co.uk'
    ) {
        return true
    }

    return false
}

export const isUswitchCustomer = (state: CheckoutState) => {
    return state.landingPage?.includes('uswitch')
}
