import 'slick-carousel/slick/slick.css'
import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { useContext, useEffect } from 'react'
import { PromoContext } from 'features/promoMode'
import { sendPageLandAnalytics } from 'features/analytics'
import { ReactComponent as Dash } from '../../../assets/dash.svg'
import { ReactComponent as Check } from '../../../assets/tick.svg'
import { ReactComponent as Arrow } from '../../../assets/arrow.svg'
import { ReactComponent as CTMLogo } from '../../../assets/ctm/logo-one-line.svg'
import PlanInclusions from '../components/planInclusions'
import TrustPilot from '../components/trustpilot'
import styles from './index.module.scss'
import './carousel-styles.scss'

interface PlanItemProps {
    children: React.ReactNode
    checked?: boolean
}
function PlanItem({ children, checked = false }: PlanItemProps) {
    return (
        <li className={styles.planItem}>
            <span className={`${checked ? styles.checked : styles.dash}`}>
                {checked ? <Check /> : <Dash />}
            </span>
            <span>{children}</span>
        </li>
    )
}

const defaultResponsiveSlickSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
}

function Landing() {
    const navigate = useNavigate()

    const { phase: promoPhase } = useContext(PromoContext)
    const promoPeriodActive = !!promoPhase

    useEffect(() => {
        sendPageLandAnalytics('CoverType', 'RiskJourney')
    }, [])

    const onSeePricesClick = (essentialsPackageId: number) => () => {
        const params = new URLSearchParams()
        params.append('package', essentialsPackageId.toString())
        params.append('contribution', '95')
        params.append('billing', 'Monthly')
        params.append('customertype', 'Homeowner')

        navigate(`/products/compare-the-market?${params.toString()}`)
        navigate(0)
    }

    const cards = [
        <div className={styles.cardContainer}>
            <div className={styles.card}>
                <div className={`${styles.cardHeader} header-boiler`}>
                    <h2>Your Boiler</h2>
                    <h4>Basic cover</h4>
                    {promoPeriodActive && !!promoPhase?.ctm?.stickers.yourBoiler && (
                        <div className={styles.discount}>
                            {promoPhase?.ctm?.stickers.yourBoiler}
                        </div>
                    )}
                </div>
                <div className={styles.cardContent}>
                    <Button
                        id="HomeCareCover_Landing_YourBoiler_SeePrices"
                        onClick={onSeePricesClick(10)}
                    >
                        See prices
                    </Button>
                    <ul>
                        <PlanItem checked>Boiler service</PlanItem>
                        <PlanItem checked>Major faults</PlanItem>
                        <PlanItem checked>Minor faults</PlanItem>
                        <PlanItem checked>Repair limit</PlanItem>
                        <PlanItem checked>Boiler and controls</PlanItem>
                        <PlanItem>Central Heating</PlanItem>
                        <PlanItem>Plumbing and drains</PlanItem>
                        <PlanItem>Home electrics</PlanItem>
                    </ul>
                </div>
            </div>
        </div>,
        <div className={styles.cardContainer}>
            <div className={styles.card}>
                <div className={`${styles.cardHeader} header-heating`}>
                    <h2>Your Heating</h2>
                    <h4>More comprehensive</h4>
                    {promoPeriodActive && !!promoPhase?.ctm?.stickers.yourHeating && (
                        <div className={styles.discount}>
                            {promoPhase?.ctm.stickers.yourHeating}
                        </div>
                    )}
                </div>
                <div className={styles.cardContent}>
                    <Button
                        id="HomeCareCover_Landing_YourHeating_SeePrices"
                        onClick={onSeePricesClick(11)}
                    >
                        See prices
                    </Button>
                    <ul>
                        <PlanItem checked>Boiler service</PlanItem>
                        <PlanItem checked>Major faults</PlanItem>
                        <PlanItem checked>Minor faults</PlanItem>
                        <PlanItem checked>Repair limit</PlanItem>
                        <PlanItem checked>Boiler and controls</PlanItem>
                        <PlanItem checked>Central Heating</PlanItem>
                        <PlanItem>Plumbing and drains</PlanItem>
                        <PlanItem>Home electrics</PlanItem>
                    </ul>
                </div>
            </div>
        </div>,
        <div className={styles.cardContainer}>
            <div className={styles.card}>
                <div className={`${styles.cardHeader} header-home`}>
                    <h2>Your Home</h2>
                    <h4>Total peace of mind</h4>
                    {promoPeriodActive && !!promoPhase?.ctm?.stickers.yourHome && (
                        <div className={styles.discount}>{promoPhase?.ctm.stickers.yourHome}</div>
                    )}
                </div>
                <div className={styles.cardContent}>
                    <Button
                        id="HomeCareCover_Landing_YourHome_SeePrices"
                        onClick={onSeePricesClick(12)}
                    >
                        See prices
                    </Button>
                    <ul>
                        <PlanItem checked>Boiler service</PlanItem>
                        <PlanItem checked>Major faults</PlanItem>
                        <PlanItem checked>Minor faults</PlanItem>
                        <PlanItem checked>Repair limit</PlanItem>
                        <PlanItem checked>Boiler and controls</PlanItem>
                        <PlanItem checked>Central Heating</PlanItem>
                        <PlanItem checked>Plumbing and drains</PlanItem>
                        <PlanItem checked>Home electrics</PlanItem>
                    </ul>
                </div>
            </div>
        </div>,
    ]

    return (
        <div className={styles.page}>
            <section className={`${styles.ctmPartnershipLogo} ${styles.hideDesktop}`}>
                <span>In partnership with</span>
                <CTMLogo />
            </section>
            <section>
                <div className={`${styles.cardsCarousel} landing-slick-slider`}>
                    <Slider
                        {...{
                            className: 'center',
                            centerMode: true,
                            infinite: false,
                            centerPadding: '60px',
                            dots: false,
                            arrows: false,
                            slidesToShow: 3,
                            nextArrow: (
                                <button type="button" aria-label="Next">
                                    <Arrow />
                                </button>
                            ),
                            prevArrow: (
                                <button type="button" aria-label="Previous">
                                    <Arrow />
                                </button>
                            ),
                            responsive: [
                                {
                                    breakpoint: 500,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '20px',
                                    },
                                },
                                {
                                    breakpoint: 575,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '100px',
                                    },
                                },
                                {
                                    breakpoint: 650,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '125px',
                                    },
                                },
                                {
                                    breakpoint: 750,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '150px',
                                    },
                                },
                                {
                                    breakpoint: 850,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '175px',
                                    },
                                },
                                {
                                    breakpoint: 950,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '250px',
                                    },
                                },
                                {
                                    breakpoint: 1070,
                                    settings: {
                                        ...defaultResponsiveSlickSettings,
                                        centerPadding: '275px',
                                    },
                                },
                            ],
                        }}
                    >
                        {cards}
                    </Slider>
                </div>
            </section>

            <PlanInclusions />

            <TrustPilot />
        </div>
    )
}
export default Landing
