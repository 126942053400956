import { useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { PromoContext } from 'features/promoMode'
import { DEFAULT_PRICEBOOK } from 'contexts/checkout/defaultState'
import { sendPageLandAnalytics } from 'features/analytics'
import { ReactComponent as CTMLogo } from '../../../assets/ctm/logo-plans-table.svg'
import styles from './index.module.scss'
import PlanInclusions from '../components/planInclusions'
import PlansTable from '../components/plansTable'
import TrustPilot from '../components/trustpilot'
import MicroStar from '../components/trustpilot/microStar'
import BoilerBrands from '../components/boilerBrands'
import FAQs from '../components/faq'
import Marketing from '../components/marketing'
import ContactUs from '../components/contactUs'
import { CtmPrices, emptyPrices, getPrices } from '../api'

function HomeownerProducts() {
    const loc = useLocation()
    const searchParams = new URLSearchParams(loc.search)
    const packageId = searchParams.get('package')
    const contribution = searchParams.get('contribution')
    const billing = searchParams.get('billing')?.toLowerCase()
    const [prices, setPrices] = useState<CtmPrices>(emptyPrices)
    const [strikethroughPrices, setStrikethroughPrices] = useState<CtmPrices | undefined>(undefined)

    const { phase: promoPhase } = useContext(PromoContext)
    const promoPeriodActive = !!promoPhase

    useEffect(() => {
        sendPageLandAnalytics('YourPrices', 'EnquiryPresentation')
    }, [])

    useEffect(() => {
        const pricebook =
            promoPeriodActive && promoPhase?.pricebooks.promoBook
                ? promoPhase?.pricebooks.promoBook
                : DEFAULT_PRICEBOOK
        const a = async () => {
            setPrices(await getPrices(pricebook))
            if (promoPeriodActive && promoPhase?.pricebooks.fullPriceBook) {
                setStrikethroughPrices(await getPrices(promoPhase?.pricebooks.fullPriceBook))
            }
        }
        a()
    }, [packageId])

    return (
        <div className={styles.page}>
            <section className={styles.ctmPartnershipLogo}>
                <span>In partnership with</span>
                <CTMLogo />
            </section>
            <div className={styles.heading}>
                <div>
                    <h2 className={styles.hideDesktop}>
                        {promoPeriodActive ? (
                            promoPhase?.ctm?.plans.headerMobile
                        ) : (
                            <>Boiler & Central Heating Protection You Can Rely On</>
                        )}
                    </h2>
                    <h2 className={styles.hideMobile}>
                        {promoPeriodActive ? (
                            promoPhase?.ctm?.plans.header
                        ) : (
                            <>Choose Your Boiler & Home Care Plan With Hometree</>
                        )}
                    </h2>
                    <p className={styles.hideDesktop}>
                        {promoPeriodActive ? (
                            promoPhase?.ctm?.plans.subheader
                        ) : (
                            <>
                                Avoid expensive repair bills and join one of the UK&apos;s leading
                                home cover and energy services providers today
                            </>
                        )}
                    </p>
                </div>
                <div className={styles.subheading}>
                    <div className={styles.tp}>
                        <MicroStar />
                    </div>
                </div>
            </div>
            <section className={styles.section}>
                <PlansTable
                    type="homeowner"
                    contribution={contribution || ''}
                    billing={billing || ''}
                    essentialsPackageId={packageId || ''}
                    monthlyPrices={prices}
                    promoFullMonthlyPrices={strikethroughPrices}
                />
            </section>

            <BoilerBrands />

            <PlanInclusions />

            <TrustPilot />

            <FAQs />

            <Marketing />

            <ContactUs />
        </div>
    )
}
export default HomeownerProducts
