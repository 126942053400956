import Button from 'components/button'
import Modal from 'components/modal'
import { useEffect, useState } from 'react'
import { ReactComponent as HometreeLogo } from 'assets/logos/mable-hometree.svg'
import { isToday, isWeekend } from 'date-fns'
import MicroStar from 'features/ctm/components/trustpilot/microStar'
import styles from './index.module.scss'

interface Props {
    countdown: boolean
    showPromoBanner: boolean
}
function NavBar({ countdown, showPromoBanner }: Props): JSX.Element {
    const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false)
    const [isWithinOfficeHours, setIsWithinOfficeHours] = useState(false)

    useEffect(() => {
        const date = new Date()
        const busyWeekendSaturday = isToday(new Date('2024-01-27'))
        const busyWeekendSunday = isToday(new Date('2024-01-28'))
        const hours = date.getHours()
        if (
            (busyWeekendSaturday && hours >= 9 && hours <= 16) ||
            (busyWeekendSunday && hours >= 9 && hours <= 12) ||
            (!isWeekend(date) && hours >= 9 && hours <= 16)
        ) {
            setIsWithinOfficeHours(true)
        } else {
            setIsWithinOfficeHours(false)
        }
    }, [])

    const countdownStyle = countdown ? styles.countdown : styles.noCountdown

    return (
        <>
            <div
                className={`${styles.content} ${styles.navbar} ${
                    showPromoBanner ? countdownStyle : styles.disabled
                }`}
            >
                <div className={`${styles.content}`}>
                    <a href="https://www.hometree.co.uk/" aria-label="Go to homepage">
                        <div className={styles.logo}>
                            <HometreeLogo title="Hometree logo" />
                        </div>
                    </a>
                    {/* <div className={styles.contactDetails}> */}
                    {/* <div>
                            <MicroStar />
                        </div> */}
                    {isWithinOfficeHours ? (
                        <>
                            <div className={`${styles.contactUs} ${styles.desktopOnly}`}>
                                <div className={styles.tpContainer}>
                                    <MicroStar />
                                </div>
                                <div className={styles.contactInfo}>
                                    <strong>
                                        Have you seen a better quote from another provider?
                                    </strong>
                                    <br />
                                    Call <a href="tel:0330 912 4843">0330&nbsp;912&nbsp;4843</a> and
                                    we&apos;ll beat or match any quote.
                                </div>
                            </div>
                            <div className={`${styles.mobileOnly}`}>
                                <Button onClick={() => setIsContactUsModalOpen(true)}>
                                    Contact Us
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Button onClick={() => setIsContactUsModalOpen(true)}>Contact Us</Button>
                    )}
                    {/* </div> */}
                </div>
            </div>

            <Modal
                isOpen={isContactUsModalOpen}
                onClose={() => setIsContactUsModalOpen(false)}
                buttonText="Close"
                className={styles.contactUsModal}
            >
                <h2>Contact Us</h2>
                <a href="tel:0330 912 4843">
                    <Button>Give us a call</Button>
                </a>
                <span>0330 912 4843</span>

                <hr />

                <h3>Phone</h3>
                <p>
                    <strong>Monday to Friday:</strong> 9am - 5pm
                    <br />
                    {new Date() < new Date('2024-01-29') ? (
                        <>
                            <p>
                                <strong>Saturday:</strong> 9am - 5pm
                                <br />
                                <strong>Sunday:</strong> 9am - 1pm
                            </p>
                        </>
                    ) : (
                        <>
                            <strong>Saturday to Sunday:</strong> Closed
                        </>
                    )}
                </p>

                <h3>Form</h3>
                <p>
                    Contact us{' '}
                    <a target="_blank" href="https://www.hometree.co.uk/contact/" rel="noreferrer">
                        here
                    </a>
                </p>
            </Modal>
        </>
    )
}

export default NavBar
