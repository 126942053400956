import { Promo } from '../promo.class'

export const corePromo = new Promo()

corePromo.addPhase({
    period: {
        start: new Date(2024, 2, 13, 9, 0, 0),
        end: new Date(2024, 2, 14, 23, 59, 59),
    },

    countdown: true,
    pricebooks: {
        promoBook: 45,
        fullPriceBook: 33,
    },
    banner: {
        primaryText: <>Get up to 23% off + £10 Gift Card with selected plans&nbsp;</>,
        primaryTextMobile: <>Plus save up to 23% off select cover plans&nbsp;</>,
        secondaryText: (
            <>
                <span>
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2024/03/March-Sale-Event-Terms-and-Conditions.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    apply
                </span>
            </>
        ),
        countdownTextMobile: <>£10 gift card ends</>,
    },
    checkout: {
        strikethrough: true,
    },
})

corePromo.addPhase({
    period: {
        start: new Date(2024, 2, 15, 0, 0, 0),
        end: new Date(2024, 2, 22, 8, 59, 59),
    },
    banner: {
        primaryText: <>March Sale Event - get up to 23% off selected plans&nbsp;</>,
        primaryTextMobile: <>Get up to 23% off selected plans&nbsp;</>,
        countdownTextMobile: <>Limited Time Offer</>,
    },
    countdown: false,
})

corePromo.addPhase({
    period: {
        start: new Date(2024, 2, 22, 9, 0, 0),
        end: new Date(2024, 2, 26, 23, 59, 59),
    },
    banner: {
        primaryText: <>March Sale Event - get up to 23% off selected plans</>,
        primaryTextMobile: <>Get up to 23% off selected plans</>,
        countdownTextMobile: <>Offer ends</>,
    },
    countdown: true,
})
